import React, { useEffect } from "react";
import Home from "./components/Home/Home";
import "./App.css";
import telegramAnalytics from '@telegram-apps/analytics';
import { ANALYTICS_TOKEN, ANALYTICS_IDENTIFIER } from './constants/constants';

const App = () => {
  useEffect(() => {
    // Initialize analytics when the app starts
    telegramAnalytics.init({
      token: ANALYTICS_TOKEN,
      appName: ANALYTICS_IDENTIFIER,
    });
  }, []);

  return (
    <div className="App">
      <div className="mainContainer">
        <Home />
      </div>
    </div>
  );
};

export default App;
