// API Base URL
export const BASE_URL = "/api";

// Project IDs
export const PROJECT_IDS = ['BTC', 'ETH', 'SOL', 'BNB', 'WIF'];

// API Endpoints
export const API = {
  MODEL_STATE: `${BASE_URL}/model_state`,
};

export const PH_GENERAL_OVERVIEW = Array.from({ length: 9 }, () => ({
  label: '--',
  value: '0%',
  trend: 'N/A',
}));
// Default selected option
export const DEFAULT_SELECTED_OPTION = 'BTC';

// Custom color scale for heatmap
export const CUSTOM_HEATMAP_COLORS = [
  [0, 'rgba(255,99,71,0.8)'],
  [0.1, 'rgba(255,140,0,0.8)'],
  [0.2, 'rgba(255,165,0,0.8)'],
  [0.3, 'rgba(255,215,0,0.8)'],
  [0.4, 'rgba(255,255,0,0.8)'],
  [0.5, 'rgba(255,255,102,0.8)'],
  [0.6, 'rgba(173,255,47,0.8)'],
  [0.7, 'rgba(144,238,144,0.8)'],
  [0.8, 'rgba(50,205,50,0.8)'],
  [0.9, 'rgba(34,139,34,0.8)'],
  [1, 'rgba(0,128,0,0.8)'],
];

export const MARKET_OVERVIEW_INIT = [
  { rank: '--', symbol: '--', currentPrice: '--', predictedPrice: '--', residual: '--' },
  { rank: '--', symbol: '--', currentPrice: '--', predictedPrice: '--', residual: '--' },
  { rank: '--', symbol: '--', currentPrice: '--', predictedPrice: '--', residual: '--' },
];

export const MARKET_OVERVIEW_HOME = [
  { key: 'rank', label: 'Rank', sortable: true },
  { key: 'symbol', label: 'Symbol', sortable: true },
  { key: 'currentPrice', label: 'Current Price', sortable: false },
  { key: 'predictedPrice', label: 'Predicted Price', sortable: false },
  { key: 'residual', label: 'Residual %', sortable: true },
];

export const MARKET_OVERVIEW_MODEL = [
  { key: 'collectionName', label: 'Model Name', sortable: false },
  { key: 'residual', label: 'Residual', sortable: true },
  { key: 'r2_train', label: 'R2 Train', sortable: false },
  { key: 'mse_train', label: 'MSE Train', sortable: false },
];

export const MARKET_FOR_MODEL = [
  { key: 'symbol', label: 'Symbol', sortable: false },
  { key: 'currentPrice', label: 'Price', sortable: false },
  { key: 'residual', label: 'Residual', sortable: true },
];

export const STRATEGIES_MODEL = [
  { key: 'collectionName', label: 'Model Name', sortable: false },

  { key: 'sharpe', label: 'Sharpe', sortable: false },
  { key: 'sortino', label: 'Sortino', sortable: true },
  { key: 'winrate', label: 'Win Rate', sortable: true },
];

export const TRADES_STRATEGY = [
  { key: 'timestamp', label: 'Time', sortable: true },
  { key: 'price', label: 'Price', sortable: false },
  { key: 'position_change', label: 'Position Change', sortable: false },
  { key: 'side', label: 'Side', sortable: false },
];


export const tokenAddresses = {
  Y: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
};

export const MinTokenAmount = 0;


export const BLUR_OPTIONS = {
  projects: {
    visible: ["BTC", "ETH", "SOL"],
  },
  timeframes: {
    visible: ["high"],
  },
};

export const TELEGRAM_DOCS_URL = "https://t.me/+VWbj4ZntLtBjMzdk";
export const TELEGRAM_BOT_URL = "https://t.me/y_tapp_bot?start=tgapp";

export const ANALYTICS_TOKEN = "eyJhcHBfbmFtZSI6InlfdGFwcF9hbmFseXRpY3NfaWRlbnRpZmllciIsImFwcF91cmwiOiJodHRwczovL3QubWUveV90YXBwX2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL3RnYXBwLnlvcmFjbGUuYWkvIn0=!TvoMI8JfsNsndaU2F0jmRW62cgDLJ9R7P8KwwcLzRkw=";
export const ANALYTICS_IDENTIFIER = "y_tapp_analytics_identifier";